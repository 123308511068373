<template>
  <div class="file-upload row between-xs middle-xs">
    <input
      ref="file"
      type="file"
      style="display: none"
      @change="handleFileChange"
    />
    <div class="col-xs-3">
      <button class="browse" @click.prevent="$refs.file.click()">
        Browse
      </button>
    </div>
    <div class="col-xs-7">
      <span class="file">{{ fileText }}</span>
    </div>
    <div class="col-xs-2 p0">
      <button class="plus" @click.prevent="$refs.file.click()">
        +
      </button>
    </div>
  </div>
</template>
<script>
import FileUploadMixin from "./FileUploadMixin";
export default {
  mixins: [FileUploadMixin],
  props: {
    section: {
      type: String,
      default: ""
    },
    chunkSize: {
      type: Number,
      default: 2000
    },
    uploadUrl: {
      type: String,
      default: process.env.VUE_APP_UPLOAD_URL
    }
  },
  data() {
    return {
      file: {},
      start: 0,
      chunkIndex: 0,
      reader: new FileReader(),
      formData: new FormData()
    };
  },
  computed: {
    fileText() {
      if (!this.file.name) {
        return "No Files Selected";
      }
      let fileName = this.file.name;
      if (fileName.length > 35) {
        fileName = `${fileName.substr(0, 10)}...${fileName.substr(
          fileName.length - 10,
          fileName.length
        )}`;
      }
      return fileName;
    },
    shouldChunk() {
      return this._fileSize > this._chunkSize;
    },
    _chunkSize() {
      return this.chunkSize * 1024;
    },
    _fileSize() {
      return this.file.size || 0;
    },
    totalChunkCount() {
      return Math.ceil(this._fileSize / this._chunkSize);
    },
    nextChunk() {
      return this.start + this._chunkSize + 1;
    },
    slice() {
      let slice = this.file.mozSlice
        ? this.file.mozSlice
        : this.file.webkitSlice
        ? this.file.webkitSlice
        : this.file.slice
        ? this.file.slice
        : () => {};

      return slice.bind(this.file)(this.start, this.nextChunk);
    },
    chunkData() {
      return {
        dztotalfilesize: this._fileSize,
        dzchunksize: this._chunkSize,
        dztotalchunkcount: this.totalChunkCount,
        dzchunkbyteoffset: this.start,
        dzchunkindex: this.chunkIndex
      };
    },
    percentDone() {
      return Math.floor((this.start + this._chunkSize / this._fileSize) * 100);
    }
  }
};
</script>
<style lang="scss" scoped src="./_file_upload.scss"></style>
