import axios from "axios";
export default {
  methods: {
    async handleFileChange() {
      //get the file from the input
      this.file = this.$refs.file.files[0];

      //fire first two events
      this.$emit("dr-file-added", this.file);
      this.$emit("dr-before-upload", this.file);

      //if the file is smaller than the chunk size, parse the chunks
      //else just sent the request

      if (this.shouldChunk) {
        await this.parseChunk();
      } else {
        await this.sendRequest(true);
      }
    },
    async parseChunk() {
      let lastChunk = false;
      let end = this.nextChunk;

      if (this._fileSize - end < 0) {
        end = this._fileSize;
        lastChunk = true;
      }

      return await this.sendRequest(lastChunk);
    },
    async sendRequest(lastChunk) {
      let formData = new FormData();
      if (!this.shouldChunk) {
        formData.append("files[]", this.file, this.file.name);
      } else {
        let chunkData = this.chunkData;
        Object.keys(chunkData).map(key => {
          formData.append(key, chunkData[key]);
        });
        formData.append("files[]", this.slice, this.file.name);
      }
      formData.append("user_id", 1);

      let headers = {
        "Content-Type": `multipart/form-data;
          boundary=${formData._boundary}`,
        "X-Content-Name": this.file.name,
        "X-Content-Length": this.fileSize,
        "Content-Length": this.slice.size
      };

      return await axios({
        method: "post",
        url: this.uploadUrl,
        data: formData,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        config: {
          headers: headers
        }
      })
        .then(response => {
          this.$emit(
            "dr-file-uploading",
            this.file,
            this.percentDone,
            this.file.size
          );

          if (lastChunk) {
            this.$emit("dr-file-upload-success", this.file, response.data);

            return response;
          }

          // this.$emit(
          //   "dr-file-uploading",
          //   this.file,
          //   this.percentDone,
          //   this.start
          // );

          this.chunkIndex++;
          this.start += this.slice.size;

          this.parseChunk();
        })
        .catch(error => error.response);
    }
  }
};
